import React, { useEffect } from 'react';

// Hooks & Querys & Context
import { useSiteState } from '../../lib/siteContext';

// Components
import Layout from '../../components/layout';
import Seo from '../../components/global/seo';
import UserMenu from '../../components/user/userMenu';

const UserInfoItem = ({ label, value }) => {
  return (
      <li className="flex flex-col">
        <span className="text-sm text-white/50">{label}</span>
        <div className="border-b border-white/50 w-full max-w-md py-2">{value}</div>
      </li>
  )
}

const Profile = () => {

    const { 
        setFooterHidden, 
        setHeaderDark, 
        setFooterTheme, 
        user 
      } = useSiteState();

    useEffect(() => {    
        document.documentElement.setAttribute('data-theme', `theme-black`);
        document.documentElement.style.setProperty(`--doc-bg`, '#141414');                  
        setHeaderDark(false)    
        setFooterHidden(false)
        setFooterTheme('theme-none')
        user && console.log(user)
    }, [user, setFooterHidden, setFooterTheme, setHeaderDark]);    

  return (
    <Layout>      
      <Seo title="Profile" metaTitle="Profile" hidden={true} />
      <div className="block w-full h-24 lg:h-header lg:mb-20"></div>
      <UserMenu />
      <section className="grid grid-cols-1 md:grid-cols-2 md:items-start gap-8 lg:gap-gutter grid-flow-row py-12 lg:py-20 md:min-h-user">        
        <div className="px-4 text-lg">Profile</div>
        {user && (
          <ul className="grid grid-cols-1 gap-6 px-4">
            {user.firstName && <UserInfoItem label="First name" value={user.firstName}/>}
            {user.firstName && <UserInfoItem label="Last name" value={user.lastName}/>}
            {user.email && <UserInfoItem label="Email address" value={user.email}/>}          
          </ul>
        )}
      </section>      
    </Layout>
  )
}

export default Profile